<!-- 活动统计 -->
<template>
  <div class="real">
    <div class="header">
      <span class="title">活动类型：</span>
      <div class="picker">
        <el-select v-model="value" placeholder="请选择" @change="handleChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <span class="title">活动名称：</span>
      <div class="picker">
        <div class="gridname" @click="showPopup">
          <span
            class="gridspan"
            :style="{ display: eventName ? 'none' : 'block' }"
          >
            请选择活动名称
          </span>
          <span v-if="eventName">{{ eventName }}</span>
        </div>
        <el-dialog
          title="选择活动名称"
          width="800px"
          :visible.sync="modalVisible"
          :z-index="12"
          :destroy-on-close="true"
          :close-on-click-modal="false"
        >
          <activity
            ref="position"
            @close="modalVisible = false"
            @selection-change="handleSelectiononeChange"
          />
        </el-dialog>
      </div>
      <el-button type="primary" @click="query">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>
    <div class="main">
      <el-checkbox-group v-model="checkList" @change="handleCheckboxChange">
        <el-checkbox label="人员类型"></el-checkbox>
        <el-checkbox label="性别"></el-checkbox>
        <el-checkbox label="评价情况"></el-checkbox>
        <el-checkbox label="年龄分布"></el-checkbox>
        <el-checkbox label="所属网格"></el-checkbox>
        <el-checkbox label="人员数量"></el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="footer">
      <div class="box" v-show="checkList.includes('人员类型')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian1.png" alt="" />
          <span>人员类型</span>
        </div>
        <div class="weibu">
          <div id="echarts-item1" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div class="box" v-show="checkList.includes('性别')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian1.png" alt="" />
          <span>性别占比</span>
        </div>
        <div class="weibu">
          <div id="echarts-item2" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div class="box" v-show="checkList.includes('评价情况')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian1.png" alt="" />
          <span>评价情况</span>
        </div>
        <div class="weibu">
          <div id="echarts-item3" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div class="box" v-show="checkList.includes('年龄分布')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian2.png" alt="" />
          <span>年龄分布</span>
        </div>
        <div class="weibu">
          <div id="echarts-item4" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div class="box" v-show="checkList.includes('所属网格')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian2.png" alt="" />
          <span>所属网格</span>
        </div>
        <div class="weibu">
          <div id="echarts-item5" style="height: 100%; width: 100%"></div>
        </div>
      </div>
      <div class="box" v-show="checkList.includes('人员数量')">
        <div class="toubu">
          <img src="../../../../../assets/community/tupian3.png" alt="" />
          <span>人员数量</span>
        </div>
        <div class="weibu">
          <span>累计参与活动人数</span>
          <div class="dss">
            <span class="people">{{ this.total }}</span>
            <span>人</span>
          </div>

          <img src="../../../../../assets/community/tupian4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import activity from "./activity.vue";
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { activity },
  data() {
    //这里存放数据
    return {
      api,
      value: "",
      id: "",
      eventName: "",
      eventId: "",
      type: "",
      eventType: "",
      total: 0, //人员数量
      ageNumList: [], //年龄
      ageList: [], //年龄范围
      reviewNum: 0, //已评价
      noReviewNum: 0, //未评价
      maleNum: 0, //男性
      femaleNum: 0, //女性
      flowInNum: 0, //流入人口
      residentNum: 0, //常住人口
      gridNameList: [],
      gridNumList: [], //所属网格
      customSelectionRows: "",
      echartsitem1: null,
      echartsitem2: null,
      echartsitem3: null,
      echartsitem4: null,
      echartsitem5: null,
      modalVisible: false,
      checkList: [
        "人员类型",
        "性别",
        "评价情况",
        "年龄分布",
        "所属网格",
        "人员数量",
      ],
      options: [
        { label: "公益活动", value: "1" },
        { label: "教育讲座", value: "2" },
        { label: "文艺表演", value: "3" },
        { label: "体育竞赛", value: "4" },
        { label: "节日庆典", value: "5" },
        { label: "防灾演练", value: "6" },
        { label: "环保行动", value: "7" },
        { label: "其他", value: "8" },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  watch: {},
  mounted() {
    this.getresidentType(); // 人员类型
    this.getresidentGender(); //性别占比
    this.getresidentReview(); //评价情况
    this.getresidentAge(); //年龄分布
    this.getresidentNum(); //人员数量
    this.getresidentGridNum(); //所属网格
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.handleCheckboxChange();
      });
    });
  },
  //方法集合
  methods: {
    //选择框
    handleCheckboxChange() {
      if (this.checkList.includes("人员类型")) {
        this.getechartsone();
      }
      if (this.checkList.includes("性别")) {
        this.getechartstwo();
      }
      if (this.checkList.includes("评价情况")) {
        this.getechartsthree();
      }
      if (this.checkList.includes("年龄分布")) {
        this.getechartsfour();
      }
      if (this.checkList.includes("所属网格")) {
        this.getechartsfive();
      }
    },
    //查询
    query() {
      this.eventId = this.id;
      this.eventType = this.type;
      this.getresidentType(); // 人员类型
      this.getresidentGender(); //性别占比
      this.getresidentReview(); //评价情况
      this.getresidentAge(); //年龄分布
      this.getresidentNum(); //人员数量
      this.getresidentGridNum(); //所属网格
    },
    //重置
    reset() {
      this.id = "";
      this.type = "";
      this.value = "";
      this.eventName = "";
      this.eventId = "";
      this.eventType = "";
      this.getresidentType(); // 人员类型
      this.getresidentGender(); //性别占比
      this.getresidentReview(); //评价情况
      this.getresidentAge(); //年龄分布
      this.getresidentNum(); //人员数量
      this.getresidentGridNum(); //所属网格
    },
    handleChange(value) {
      this.type = value;
    },
    //打开小区弹窗
    showPopup() {
      this.modalVisible = true; // 点击输入框时显示弹窗
    },
    //小区名称
    handleSelectiononeChange(selectionRows) {
      this.customSelectionRows = selectionRows;
      console.log(this.customSelectionRows);
      this.customSelectionRows.forEach((obj) => {
        if (obj.eventName) {
          this.eventName = obj.eventName;
        }
        if (obj.id) {
          this.id = obj.id;
        }
      });
    },

    // 人员数量
    async getresidentNum() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };

      try {
        const res = await api.residentNum(obj);
        // 确保 res.data 存在
        if (res && res.data) {
          this.total = res.data || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.total = 0;
        }
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        // 当 res.data 为 null 或 undefined 时进行处理
        this.total = res.data || 0;
      }
    },
    // 人员类型
    async getresidentType() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };
      try {
        const res = await api.residentType(obj);
        // 确保 res.data 存在
        if (res && res.data) {
          this.flowInNum = res.data.flowInNum || 0;
          this.residentNum = res.data.residentNum || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.flowInNum = 0;
          this.residentNum = 0;
        }
        // 调用图表更新方法
        this.getechartsone();
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        this.flowInNum = 0;
        this.residentNum = 0;
      }
    },
    // 人员类型
    getechartsone() {
      this.echartsitem1 = echarts.init(
        document.getElementById("echarts-item1")
      );
      var ydata = [
        {
          name: "常住人口",
          value: this.residentNum || 0,
        },
        {
          name: "流入人口",
          value: this.flowInNum || 0,
        },
      ];
      var color = ["#5085f2", "#ffc551"];
      var xdata = ["常住人口", "流入人口"];

      const option = {
        backgroundColor: "rgba(255,255,255,1)",
        color: color,
        legend: {
          orient: "vartical",
          x: "left",
          top: "center",
          left: "70%",
          bottom: "0%",
          data: xdata,
          itemWidth: 15,
          itemHeight: 15,
          itemGap: 16,
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["60%", "90%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#ffffff",
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c} ({d}%)",
                rich: {
                  text: {
                    color: "#666",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#8693F3",
                    fontSize: 24,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 24,
                },
              },
            },
            data: ydata,
          },
        ],
      };

      this.echartsitem1.setOption(option);
    },
    // 性别占比
    async getresidentGender() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };

      try {
        const res = await api.residentGender(obj);

        // 确保 res.data 存在
        if (res && res.data) {
          this.femaleNum = res.data.femaleNum || 0;
          this.maleNum = res.data.maleNum || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.femaleNum = 0;
          this.maleNum = 0;
        }

        // 调用图表更新方法
        this.getechartstwo();
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        this.femaleNum = 0;
        this.maleNum = 0;
      }
    },
    // 性别占比
    getechartstwo() {
      this.echartsitem2 = echarts.init(
        document.getElementById("echarts-item2")
      );
      var ydata = [
        {
          name: "男",
          value: this.maleNum,
        },
        {
          name: "女",
          value: this.femaleNum,
        },
      ];
      var color = ["#91ceff", "#ffbacb"];
      var xdata = ["男", "女"];
      const option = {
        backgroundColor: "rgba(255,255,255,1)",
        color: color,
        legend: {
          orient: "vartical",
          x: "left",
          top: "center",
          left: "70%",
          bottom: "0%",
          data: xdata,
          itemWidth: 15,
          itemHeight: 15,
          itemGap: 16,
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["60%", "90%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#ffffff",
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c} ({d}%)",
                rich: {
                  text: {
                    color: "#666",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#8693F3",
                    fontSize: 24,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 24,
                },
              },
            },
            data: ydata,
          },
        ],
      };

      this.echartsitem2.setOption(option);
    },

    // 评价情况
    async getresidentReview() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };
      try {
        const res = await api.residentReview(obj);
        // 确保 res.data 存在
        if (res && res.data) {
          this.reviewNum = res.data.reviewNum || 0;
          this.noReviewNum = res.data.noReviewNum || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.reviewNum = 0;
          this.noReviewNum = 0;
        }
        // 调用图表更新方法
        this.getechartsthree();
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        this.reviewNum = 0;
        this.noReviewNum = 0;
      }
    },
    // 评价情况
    getechartsthree() {
      this.echartsitem3 = echarts.init(
        document.getElementById("echarts-item3")
      );
      var ydata = [
        {
          name: "已评价",
          value: this.reviewNum,
        },
        {
          name: "未评价",
          value: this.noReviewNum,
        },
      ];
      var color = ["#96e270", "#b8ced0"];
      var xdata = ["已评价", "未评价"];
      const option = {
        backgroundColor: "rgba(255,255,255,1)",
        color: color,
        legend: {
          orient: "vartical",
          x: "left",
          top: "center",
          left: "70%",
          bottom: "0%",
          data: xdata,
          itemWidth: 15,
          itemHeight: 15,
          itemGap: 16,
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["60%", "90%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              //图形样式
              normal: {
                borderColor: "#ffffff",
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{text|{b}}\n{c} ({d}%)",
                rich: {
                  text: {
                    color: "#666",
                    fontSize: 14,
                    align: "center",
                    verticalAlign: "middle",
                    padding: 8,
                  },
                  value: {
                    color: "#8693F3",
                    fontSize: 24,
                    align: "center",
                    verticalAlign: "middle",
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 24,
                },
              },
            },
            data: ydata,
          },
        ],
      };

      this.echartsitem3.setOption(option);
    },

    //  年龄分布
    async getresidentAge() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };
      try {
        const res = await api.residentAge(obj);
        // 确保 res.data 存在
        if (res && res.data) {
          this.ageNumList = res.data.ageNumList || 0;
          this.ageList = res.data.ageList || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.ageNumList = 0;
          this.ageList = 0;
        }
        // 调用图表更新方法
        this.getechartsfour();
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        this.ageNumList = 0;
        this.ageList = 0;
      }
    },
    //  年龄分布
    getechartsfour() {
      this.echartsitem4 = echarts.init(
        document.getElementById("echarts-item4")
      );
      var xData = this.ageList;
      var echartData = this.ageNumList;
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          borderWidth: 0,
          top: "5%",
          bottom: "15%",
          left: "12%",
          right: "5%",
          textStyle: {
            color: "#fff",
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "rgba(193, 207, 220, 1)",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "rgba(0, 0, 0, 0.72)",
                fontSize: 14,
              },
            },
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "rgba(0, 0, 0, 0.72)",
                fontSize: 15,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(193, 207, 220, 1)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "人员数量",
            type: "bar",
            barMaxWidth: 8,
            barGap: "10%",
            itemStyle: {
              normal: {
                color: "#2a5cff",
                label: {
                  show: false,
                },
                barBorderRadius: [30, 30, 30, 30], //圆角大小
              },
            },
            data: echartData,
          },
        ],
      };

      this.echartsitem4.setOption(option);
    },

    //所属网格
    async getresidentGridNum() {
      const obj = {
        eventId: this.eventId,
        eventType: this.eventType,
      };

      try {
        const res = await api.residentGridNum(obj);
        // 确保 res.data 存在
        if (res && res.data) {
          this.gridNameList = res.data.gridNameList || 0;
          this.gridNumList = res.data.gridNumList || 0;
        } else {
          // 当 res.data 为 null 或 undefined 时进行处理
          this.gridNameList = 0;
          this.gridNumList = 0;
        }
        // 调用图表更新方法
        this.getechartsfive();
      } catch (error) {
        // 处理请求错误
        console.error("Error fetching resident review:", error);
        this.gridNameList = 0;
        this.gridNumList = 0;
      }
    },
    getechartsfive() {
      this.echartsitem5 = echarts.init(
        document.getElementById("echarts-item5")
      );
      var xData = this.gridNameList;
      var echartData = this.gridNumList;
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            textStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          borderWidth: 0,
          top: "5%",
          bottom: "15%",
          left: "12%",
          right: "5%",
          textStyle: {
            color: "#fff",
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "rgba(193, 207, 220, 1)",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "rgba(0, 0, 0, 0.72)",
                fontSize: 14,
              },
            },
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            data: xData,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "rgba(0, 0, 0, 0.72)",
                fontSize: 15,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(193, 207, 220, 1)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "人员数量",
            type: "bar",
            barMaxWidth: 8,
            barGap: "10%",
            itemStyle: {
              normal: {
                color: "#9b69ff",
                label: {
                  show: false,
                },
                barBorderRadius: [30, 30, 30, 30], //圆角大小
              },
            },
            data: echartData,
          },
        ],
      };

      this.echartsitem5.setOption(option);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.real {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: center;
  background: #f9fafc;
  border-radius: 0px;
  border-bottom: 1px solid #d8ddea;
  .form-item {
    width: 80%;
  }
}
.main {
  width: 100%;
  height: 5%;
  margin-top: 1%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
}
.title {
  margin-left: 2%;
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 22px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}
.picker {
  margin-right: 2%;
  .gridname {
    position: relative;
    width: 202px;
    height: 40px;
    border: 1px solid #d9d9d9;
    text-align: left;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    transition: all 0.3s;
    .gridspan {
      position: absolute;
      left: 11px;
      color: #bfbfbf;
      pointer-events: none;
    }
  }
}
.footer {
  width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  // border: 1px dashed #d8ddea;
  // padding: 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-content: space-around;
  .box {
    width: 30%;
    height: 45%;
    padding: 1%;
    position: relative;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px dashed #d8ddea;

    .toubu {
      width: 100%;
      height: 23%;
      display: flex;
      align-items: center;
      margin-top: 2%;
      img {
        width: 11%;
        width:34px;
        height:34px;
        margin-left:  6%;
        margin-right: 2%;
      }
      span {
        font-weight: 400;
        font-size: 0.08rem;
        color: #2d3138;
        line-height: 22px;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }
    }
    .weibu {
      width: 100%;
      height: 77%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #7d8da6;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        width: 80%;
      }
      .dss {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        position: absolute;
        bottom: 40%;
        .people {
          font-weight: 700;
          font-size: 54px;
          color: #2a5cff;
          line-height: 70px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }

      img {
        width: 80%;
      }
    }
  }
}
/deep/.el-button--primary {
  background-color: #2a5cff !important;
  border: 0;
}
/deep/.el-checkbox__input.is-checked+.el-checkbox__label{
  color:#2D3138 ;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #2a5cff;
  border-color: #2a5cff;
}
</style>
